import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/index.js"
import Login from '../views/public/Login.vue'
import Invite from '../views/public/Invite.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: "Masuk",
      all: false, 
      public: true,
      onlyLoggedOut: true,
    }
  },
  {
    path: '/event/invitation',
    name: 'Event Invitation',
    component: Invite,
    meta: {
      title: "Event Invitation",
      all: false, 
      public: true,
      onlyLoggedOut: true,
    }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/private/Dashboard.vue'),
    meta: {
      title: "Dashboard",
      all: false, 
      public: false,
    }
  },
  {
    path: '/invitation',
    name: 'Invitation',
    component: () => import(/* webpackChunkName: "about" */ '../views/private/Invitation.vue'),
    meta: {
      title: "Invitation",
      all: false, 
      public: false,
    }
  },
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "about" */ '../views/public/404.vue'),
    meta: {
      title: "404",
      all: true,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const authenticated = store.state.authenticated
  const isAll = to.matched.some(record => record.meta.all)
  const onlyLoggedOut = to.matched.some(record => record.meta.onlyLoggedOut)
  const isPublic = to.matched.some(record => record.meta.public)
  
  
  if (!isAll && !isPublic && !authenticated) {
    return next({
      path: "/login"
    })
  }
  if (authenticated && onlyLoggedOut) {
    return next("/")
  }
  document.title = `SODA Live | ${to.meta.title}`
  next()
})

export default router
