<template>
  <div class="mb-12">
    
    <section class="my-0">
      <v-container class="pa-8">
        <v-row justify="center" class="mb-5">
          <v-col cols="12" class="text-h5 font-weight-normal text-center">
            <div>
              Selamat Anda telah terverifikasi untuk mengikuti event <b>{{ 'Jogja Halal Fest' }}</b>
            </div>
            <div class="body-1 line-text-second text-first mt-3">
              <v-avatar
                tile
                size="25">
                <v-img alt="sodapos"
                  contain
                  :src="require('@/assets/icon/calendar.png')">
                </v-img>
              </v-avatar>
              {{ '10 November 2022' }} <b>s/d</b> {{ '12 November 2022' }}
            </div>
            <a :href="`https://maps.google.com/?q=-7.81568001916558,110.41677376517048`" target="_blank" 
              class="subtitle-1 line-text-second text-second hover-text">
              <v-avatar
                tile
                size="25">
                <v-img alt="sodapos"
                  contain
                  :src="require('@/assets/icon/google-maps.png')">
                </v-img>
              </v-avatar>
              {{ 'PT. Era Solusi Data' }}
            </a>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3"
            v-for="(l, index) in member"
            :key="index">
            <v-card
              flat
              height="400"
              class="hover-card border-radius"
              color="transparent">
              <v-card-title class="justify-center pb-10">
                <v-avatar
                  tile
                  size="100">
                  <v-img alt="sodapos"
                    contain
                    :src="l.icon">
                  </v-img>
                </v-avatar>
                <div v-show="$vuetify.breakpoint.name !== 'xs'">
                  <span class="soda-step" v-if="index < 3"></span>
                </div>
              </v-card-title>
              <v-card-subtitle class="body-1 font-weight-bold text-center">
                {{ l.title }}
              </v-card-subtitle>
              <v-card-text class="body-1 font-weight-light line-text-second text-second text-center">
                {{ l.desc }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-5">
          <v-col :cols="$vuetify.breakpoint.xs ? '12' : '6'" class="text-center">
            <div
              small
              class="body-2 red--text" 
              style="border: 1px solid red; padding: 8px;border-radius: 5px; width: auto;"
              color="#d31145"
              outlined
              elevation="0"
              x-large>
              Anda akan otomatis diarahkan ke&nbsp;<b>Google Play Store</b>&nbsp;dalam {{ timer_display }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      member: [
        {
          title: "Minta Pihak Event",
          desc: "Mintalah kepada pihak event untuk mengirimkan email invitation.",
          icon: require("@/assets/icon/pembayaran.png")
        },
        {
          title: "Download Aplikasi SODA POS",
          desc: "Jika kamu pengguna baru SODA POS, silahkan download aplikasi SODA POS dengan menekan tombol Download Aplikasi di bawah.",
          icon: require("@/assets/icon/registrasi.png")
        },
        {
          title: "Daftarkan diri",
          desc: "Daftarkan diri pada aplikasi SODA POS",
          icon: require("@/assets/icon/pilih-paket.png")
        },
        {
          title: "Siap digunakan pada event",
          desc: "Aplikasi SODA POS siap digunakan untuk bertransaksi pada event yang diikuti.",
          icon: require("@/assets/icon/langganan.png")
        },
      ],
      timer_display: "--:--",
      countdown: null,
      minutes: 0,
      seconds: 0
    }
  },
  mounted() {
    let _self = this;
    let timer = 30 
    _self.countdown = setInterval(function () {
      _self.minutes = parseInt(timer / 60, 10);
      _self.seconds = parseInt(timer % 60, 10);

      _self.minutes = _self.minutes < 10 ? "0" + _self.minutes : _self.minutes;
      _self.seconds = _self.seconds < 10 ? "0" + _self.seconds : _self.seconds;

      _self.timer_display = _self.minutes + ":" + _self.seconds;

      if (--timer < 0) {
          timer = 0;
          clearInterval(_self.countdown)
          _self.goTo()
      }
    }, 1000);
  },
  methods: {
    goTo() {
      window.open('https://play.google.com/store/apps/details?id=com.sodapos')
    }
  }
}
</script>

<style>

</style>

