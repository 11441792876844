const Config = {
    checkDomain : () => {
      let windowLoc = window.location.hostname
      
      if(windowLoc == 'live-dev.sodapos.com'){
        return 'https://api-new.sodapos.com/'
      } else {
        return 'https://services.sodapos.com/'
      }
    }
  }
  
  export default Config